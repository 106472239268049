import { faBars, faExchange, faHandshakeAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import { Button, Nav, Offcanvas } from "react-bootstrap";


const Navbar = ({active, setActive, support, setSupportModel}) =>{
    const [canvas, setCanvas] = useState(false);

    return(
        <>
            <div className="nav-pill nav-web">
                <Nav.Link onClick={(e) => setActive(0)} className={`nav-item ${active == 0?'nav-item-active':''}`}>
                    HOME
                </Nav.Link>
                |
                <Nav.Link onClick={(e) => setActive(1)} className={`nav-item ${active == 1?'nav-item-active':''}`}>
                    TRANSACTIONS
                </Nav.Link>
                |
                <Nav.Link onClick={(e) => setSupportModel(true)} className={`nav-item-support`}>
                    SUPPORT
                </Nav.Link>
            </div>
            <div className="nav-pill nav-mobile" style={{display:'none'}}>
                <FontAwesomeIcon style={{fontSize:20}} icon={faBars}  onClick={() => setCanvas(true)}/>
            </div>

            <Offcanvas style={{width:'50%'}} show={canvas} onHide={() => setCanvas(false)} placement="end">
                <Offcanvas.Header style={{background:'rgb(227 233 233)'}} closeButton>
                <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Nav.Link onClick={(e) => setActive(0)} className={`nav-item mobile-nav-item ${active == 0?'mobile-nav-item-active':''}`}>
                  <FontAwesomeIcon icon={faHome}/> &nbsp; Home
                </Nav.Link>
                <Nav.Link onClick={(e) => setActive(1)} className={`nav-item mobile-nav-item ${active == 1?'mobile-nav-item-active':''}`}>
                    <FontAwesomeIcon icon={faExchange}/> &nbsp; Transactions
                </Nav.Link>
                <Nav.Link onClick={(e) => setSupportModel(true)} className={`nav-item-support mobile-nav-item mobile-support`}>
                    <FontAwesomeIcon icon={faHandshakeAlt}/> &nbsp;   Support
                </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Navbar;