import React, {useState, useEffect} from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import moment from "moment";

const TransactionTable = ({transaction, unitColumn}) => {
    const [unitColumnres, setUnitColumnres]=useState(false);

    useEffect(() => {
        setUnitColumnres(unitColumn);
    }, [unitColumn])
    
 
    return(
        <Table className="table-style" style={{marginBottom:30}}>
        <Thead style={{fontSize:14}}>
            <Tr>
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>Account No</Th>
            <Th>TR.Units</Th>
            <Th style={{textAlign:'right'}}>Price</Th>
            <Th style={{textAlign:'right'}}>Amount (LKR)</Th>
            {
                unitColumnres == true?
                <>
                <Th style={{textAlign:'right'}}>Unit Balance</Th>
                <Th style={{textAlign:'right'}}>Value</Th>
                </>
                :''
            }
          
            </Tr>
        </Thead>
        <Tbody style={{fontSize:14}}>
            {
                transaction.length > 0 && transaction.map((value) => {
                    return(
                        <Tr>
                            <Td style={{fontSize:12}}>{"  " + moment(value.TRANSACTION_DATE).format("DD/MM/YYYY")}</Td>
                            <Td style={{fontSize:12}}>{value.NAME}</Td>
                            <Td style={{fontSize:12}}>{value.ACCOUNT_NO}</Td>
                            <Td style={{fontSize:12}}>{parseFloat(value.TransactedUnits).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Td>
                            <Td style={{fontSize:12, textAlign:'right'}}>{(Number(value.OFFER_BID_PRICE)).toFixed(4)}</Td>
                            <Td style={{fontSize:12, textAlign:'right'}}>{parseFloat(value.AMOUNT_APPLIED).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Td>
                             {
                            unitColumnres == true?
                            <>
                                <Td style={{fontSize:12, textAlign:'right'}}>{value.unitPrice != undefined && value.unitPrice != null? (parseFloat(value.unitPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):0}</Td>
                                <Td style={{fontSize:12, textAlign:'right'}}>{value.unitPrice != undefined && value.unitPrice != null? (parseFloat(Number(value.unitPrice) * Number(value.OFFER_BID_PRICE)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):0}</Td>
                            </>
                            :''
                            }
                        </Tr>
                    )
                })
            }

        </Tbody>
        </Table>
    )
}

export default TransactionTable;