import React, { useState, useEffect } from "react";

const News = () => {
    const [news, setNews] = useState('We Provide customized solutions to your suit your invidual investment needs throgh our welth.');

    useEffect(() => {
        try{
            let news_get = sessionStorage.getItem('news');
            news_get = JSON.parse(news_get);
            let line = '';
            news_get.map((value, index) => {
                line+='* '+value['text']
            })
            setNews(line);
        }catch(error){

        }
    }, [])
    

    return(
        <div className="news">
            <div className="news-container">
                <p className="news-topic">News</p>

                <div className="form-control news-line">
                    <div className="news-line-text">{news}</div>
                </div>
            </div>
        </div>
    )
}

export default News;