import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import TransactionTable from "../table";
import TransSearch from "./search";
import Statement from "./statement";
import {
  faCloudDownload,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import PendingTable from "../pending";
import api from "../../../services/api";
import { Pagination } from "react-bootstrap";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import DetailModel from "../model/detailModel";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PdfView from "../pdf/pdfviewer";
import PdfViewr from "../pdf/pdfviewer";

const TransactionForm = ({ fundList, acountList, loading }) => {
  const [transaction, setTransaction] = useState([]);
  const [pending, setPending] = useState([]);
  const [details, setDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [unitColumn, setUnitColumn] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [balance, setBalanace] = useState(0);
  const [download, setDownload] = useState(false);
  const [transactionBalance, setTransactionBalance] = useState(null);

  useEffect(() => {
    loading(true);
    loadTableData();
  }, []);

  const loadTableData = async () => {
    try {
      let data = await api.getRequest("transactions");
      if (data.data.status == "success") {
        data.data.transactions.data.sort((a, b) => {
          return a.TRANSACTION_DATE > b.TRANSACTION_DATE ? -1 : 1;
        });
        setTransaction(data.data.transactions.data);
        setPending(data.data.pending_transactions.data);
      }
      loading(false);
    } catch (error) {
      setTransaction([]);
      setPending([]);
      loading(false);
    }
  };

  const validation = Yup.object({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      fund: "",
      account: "",
      from: "",
      to: "",
      disacription: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validation,
    // Login Request
    onSubmit: async (values) => {
      setDetails(true);
    },
  });

  const handeStatementModel = () => {
    formik.resetForm();
    setDetails(false);
  };

  const handleOnhide = () => {
    setDownload(false);
  };

  const handleSearch = async () => {
    var HostUrl = currentPage == 1 ? "" : "?page=" + currentPage;
    var fromDate = formik.values.from
      ? moment(formik.values.from).format("YYYY/MM/DD")
      : formik.values.from;
    var toDate = formik.values.to
      ? moment(formik.values.to).format("YYYY/MM/DD")
      : formik.values.to;
    let para = {
      FUND_CODE: formik.values.fund,
      from: fromDate,
      to: toDate,
      ACCOUNT_NO: formik.values.account,
    };
    const data = await api.getRequest("transactions" + HostUrl, para);
    if (data.data.status == "success") {
      let list = [];
      let priceMsg = 0;
      let unitsprice = 0;
      if (data.data.transactions.data.length > 0) {
        data.data.transactions.data.map((value, index) => {
          if (index == 0) {
            if (
              data.data.opening_balance != null &&
              data.data.opening_balance.balance != null &&
              data.data.opening_balance.balance != null
            ) {
              value["unitPrice"] = Number(data.data.opening_balance.balance);
              priceMsg = Number(data.data.opening_balance.balance);
            } else {
              // value['unitPrice'] = response.data.transactions.data[0].TransactedUnits;
              // priceMsg = response.data.transactions.data[0].TransactedUnits;
            }
          } else {
            let prv = index - 1;
            let amt = 0;
            amt =
              Number(data.data.transactions.data[prv].TransactedUnits) * -1 +
              Number(data.data.transactions.data[prv].unitPrice);
            value["unitPrice"] = amt;
            priceMsg = value["unitPrice"];
          }
        });
      }

      setTransaction(data.data.transactions.data);
      setTotalPage(data.data.transactions.last_page);
      setCurrentPage(data.data.transactions.current_page);
      setBalanace(data.data.opening_balance);

      if (formik.values.fund == "" || formik.values.fund == undefined) {
        setUnitColumn(false);
      } else {
        setTransactionBalance(Number(data.data.balance.NO_OF_UNITS) * Number(data.data.balance.REDIM_PRICE))
        setUnitColumn(true);
      }
    } else {
    }
  };

  const handleDownloadTrans = () => {
    setDownload(true);
  }

  return (
    <>
      <div className="col-md-8">
        <TransSearch
          fundList={fundList}
          acountList={acountList}
          formik={formik}
          handleSearch={handleSearch}
        />
      </div>
      <div
        className="col-md-4"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Statement formik={formik} />
      </div>
      <div
        className="col-md-8"
        style={{
          marginTop: 25,
        }}
      >
        <div
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="table-heading">Transactions <br/><span style={{fontSize:12}}>{transactionBalance != null && !isNaN(transactionBalance)?' Latest Account Balance (Rs.) - '+parseFloat(transactionBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):null}</span></h3>
          <a onClick={handleDownloadTrans} className="forget-password">
            Save as pdf &nbsp;{" "}
            <FontAwesomeIcon style={{ color: "#000" }} icon={faCloudDownload} />
          </a>
        </div>
        <PerfectScrollbar style={{ height: 250, overflow: "scroll" }}>
          <TransactionTable transaction={transaction} unitColumn={unitColumn} />
        </PerfectScrollbar>
        {/* <div  className="d-flex align-items-center justify-content-center">
                <ReactPaginate
                 breakLabel="..."
                 nextLabel="next >"
                //  onPageChange={handlePageClick}
                 pageRangeDisplayed={5}
                 pageCount={30}
                 previousLabel="< previous"
                 renderOnZeroPageCount={null}                      
                />
              </div> */}
      </div>
      <div
        className="col-md-4"
        style={{
          marginTop: 25,
        }}
      >
        <div
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="table-heading">Pending(s)</h3>
        </div>
        <PerfectScrollbar style={{ height: 250, overflow: "scroll" }}>
          <PendingTable pending={pending} />
        </PerfectScrollbar>
        <DetailModel
          show={details}
          onHide={handeStatementModel}
          formik={formik}
          acountList={acountList}
          fundList={fundList}
        />
        <PdfViewr show={download} onHide={handleOnhide} transaction={transaction} unitColumn={unitColumn} fromDate={formik.values.from} toDate={formik.values.to} account={formik.values.account} fund={formik.values.fund} fundList={fundList}/>
      </div>
    </>
  );
};

export default TransactionForm;
