import React from "react";
import logo from '../../../assets/images/logo.png';
import { Navbar } from "react-bootstrap";
import Downer from '../downer/index';
import FundCounter from '../../home/fund_count';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

const TopNav = ({counter, downer, sticky = true, equityfund, fixedfund, signOutMobile=true}) => {

    const history = useHistory();

    const handleRoute = () => {
        let token = sessionStorage.getItem('token');
        if(token != '' && token != null && token != undefined){
            history.push('/home');
        }else{
            history.push('/');
        }
    } 

    const handleLogout = () => {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('token')
        history.push('/');
    }

    return(
        <Navbar className="logo"  expand="lg" sticky={sticky == false?'':'top'} >
            <Navbar.Brand onClick={handleRoute} style={{cursor:'pointer'}}>
                <img alt="Senfin Asset Management" src={logo} className="logo-style" />
            </Navbar.Brand>
            <Navbar.Collapse className={(counter && downer) == false?`justify-content-end`:`justify-content-space-between`}>
                {
                    counter == true?
                    <FundCounter equityfund={equityfund} fixedfund={fixedfund}/>
                    :''
                }
                {
                    downer == true?
                    <Downer/>
                    :
                    ''
                }
            </Navbar.Collapse>
            {
                signOutMobile == true?
                <p style={{marginBottom:0, paddingBottom:0}} onClick={handleLogout} className="account-number mobile-signout"><FontAwesomeIcon icon={faSignOut}/>&nbsp;&nbsp;<strong>Sign out</strong></p>
                :null
            }
           
        </Navbar>
    )
}

export default TopNav;